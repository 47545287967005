.mobile-horizontal-menu {
  @media (max-width: 767px) {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
.account-menu{
  @media (max-width: 767px) {
    display: block;
    .nav-item{
      width: 100%;
      text-align: center;
    }
  }
}
