// var for boxshadow of gradient buttons
// Buttons
.btn {
  // to change cursor of disabled button
  &:disabled {
    cursor: default;
  }
  // for white button
  &.btn-white {
    color: $body-color;
    &:hover,
    &:focus,
    &:active {
      color: $body-color !important;
    }
  }

  &.btn-primary{
    color: $uzakrota_gri !important;
    &:hover{
      opacity: .75;
      color: $uzakrota_gri;
    }
  }

  // box shadow for gradient colors
  &[class*="bg-gradient-"] {
    color: $white;
    &:hover {
      box-shadow: $gradient-button-bs;
    }
  }

  // For Boxshadow on hover of normal light, dark and secondary btn
  &.btn-secondary {
    color: $white;
    &:hover {
      box-shadow: 0 8px 25px -8px rgba($secondary, 1);
    }
  }
  &.btn-dark {
    &:hover {
      box-shadow: 0 8px 25px -8px rgba($dark, 1);
    }
  }
  &.btn-light {
    &:hover {
      box-shadow: 0 8px 25px -8px rgba($light, 1);
    }
  }

  // For Light,dark and secondary outline btns
  &.btn-outline-secondary,
  &.btn-outline-dark,
  &.btn-outline-light {
    border: 1px solid;
  }

  // Flat Secondary, Light and Dark
  &.btn-flat-secondary {
    &:hover {
      background-color: rgba($secondary, 0.1);
    }
    &:active,
    &:focus {
      background: $secondary;
    }
  }

  &.btn-flat-dark {
    &:hover {
      background-color: rgba($dark, 0.1);
    }
    &:active,
    &:focus {
      background: $dark;
      color: $white;
    }
  }

  &.btn-flat-light {
    &:hover {
      background-color: rgba($light, 0.1);
    }
    &:active,
    &:focus {
      background: $light;
    }
  }

  // to remove extra spacing because of border in btn outline
  &[class*="btn-outline-"] {
    padding-top: calc(#{$btn-padding-y} - 1px);
    padding-bottom: calc(#{$btn-padding-y} - 1px);
    &.btn-lg {
      padding-top: calc(#{$btn-padding-y-lg} - 1px);
      padding-bottom: calc(#{$btn-padding-y-lg} - 1px);
    }
    &.btn-sm {
      padding-top: calc(#{$btn-padding-y-sm} - 1px);
      padding-bottom: calc(#{$btn-padding-y-sm} - 1px);
    }
  }

  // Relief Buttons Secondary, Light and Dark
  &.btn-relief-secondary {
    background-color: $secondary;
    box-shadow: inset 0 -3px 0 0 rgba($pure-black, 0.2);
    color: $white;

    &:hover {
      color: $white;
    }

    &:active,
    &:focus {
      outline: none;
      transform: translateY(3px);
    }
  }
  &.btn-relief-light {
    background-color: $light;
    box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.2);
    color: $white;

    &:hover {
      color: $white;
    }

    &:active,
    &:focus {
      outline: none;
      transform: translateY(3px);
    }
  }
  &.btn-relief-dark {
    background-color: $dark;
    box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, 0.1);
    color: $white;

    &:hover {
      color: $white;
    }

    &:active,
    &:focus {
      outline: none;
      transform: translateY(3px);
    }
  }

  // For btn-icon
  &.btn-icon {
    padding: 0.715rem 0.736rem;
  }
  &.btn-sm {
    &.btn-icon {
      padding: 0.5rem;
    }
  }
  &.btn-lg {
    &.btn-icon {
      padding: 1rem;
    }
  }

  // Remove focus/active focus outline
  &.btn-square {
    @include button-square-variant(0);
  }

  &.btn-round {
    @include button-round-variant(2rem);
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    outline: none;
    box-shadow: none !important;
  }
  &.btn-icon {
    svg {
      height: 1.1rem;
      width: 1.1rem;
    }
  }
  &.btn-darkGray {
    background-color: $darkGray;
    color: $white;
  }
}
// Button Group
.btn-group {
  .react-ripples:not(:last-child) {
    .btn {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .react-ripples:not(:first-child) {
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.btn-group-vertical {
  .react-ripples:not(:last-child) {
    .btn {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
  .react-ripples:not(:first-child) {
    .btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
// For White Btn // commented because of tour btns
// .btn.btn-outline-white,
// .btn-white {
//   &:hover {
//     color: $black !important;
//   }
// }

// Added cursor pointer as in latest bootstrap do not have this
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
}

// For Waves Padding
.waves-button-input {
  padding: unset;
}
