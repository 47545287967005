$uzakrotaColor: #fbba00;

html, body {
  height: inherit !important;
  width: inherit !important;
  min-height: inherit !important;
  min-width: inherit !important;
  overflow: inherit !important;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', 'Arial', sans-serif;
  color: #4a4a4a;
}

.expo-company-staff-box {
  min-height: 70px;
  position: relative;
}

.expo-image {
  height: 150px;
  width: 100%;
  background-size: 200px;
  background-position: center;
  background-repeat: no-repeat;
}

.expo-single-box {
  .card-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.pageLoading {
  background: rgba(0, 0, 0, .3);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s ease;
  z-index: 999;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  & > div {
    width: 200px;
    height: 200px;
    left: 50%;
    position: absolute;
    top: 50%;
    margin-left: -100px;
    margin-top: -100px;
  }
}

.dropdown-user {
  &.show {
    .dropdown-user-link {
      background-color: #fefefe;

      span.user-name {
        color: $uzakrotaColor;
      }
    }
  }
}

.ticket-card {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;

  .ticket-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .55;
  }

  .card-header {
    position: relative;
  }

  .list-group-item {
    padding: 5px 10px;
  }
}

.buy-ticket-button {
  border-radius: 0px 0px 10px 10px !important;
}

.w-80 {
  width: 80%;
}

/**
#zmmtg-root{
  width: 500px !important;
  height: 400px !important;
  left: 50%;
  margin-left: -250px;
  bottom: 100px;
  top: auto !important;
  border-radius: 5px;
  #content{
    width: 100% !important;
    height: 100% !important;
  }
  .meeting-client{
    left: 20%;
    width: 80%;
    z-index: 99999;
    .meeting-client-inner{
      position: relative;
    }
  }
}
**/

.wrapper {
  & > .footer {
    height: inherit;
    width: inherit;
    display: inherit;
    -webkit-box-pack: inherit;
    position: inherit;
    left: inherit;
    background: inherit;
    color: inherit;
    font-size: inherit;
    z-index: inherit;
  }
}

.emoji-group {
  position: fixed;
  width: 260px;
  bottom: 99px;
  background: #fff;
}

.chatbox {
  textarea {
    padding: 5px;
    resize: none;
  }

  .avatar-sq {
    height: 32px;
    width: 32px;
  }

  .chat-message {
    padding: 3px 5px;

    p {
      margin-bottom: 3px;
    }
  }
}

.caret-off::after, .caret-off::before {
  display: none !important;
}

.text-uzakrota-gray {
  color: $uzakrota_gri;
}

.expo-staff {
  .users-list {
    .staff-single {
      //height: 275px;
      background: rgba(0, 0, 0, 0) linear-gradient(rgb(244, 245, 250) 6rem, rgb(255, 255, 255) 0%) repeat scroll 0% 0%;
      padding-bottom: 10px;

      .profile-p {
        height: 80px;
        line-height: 150px;
        margin-bottom: 60px;
      }

      .profile-info {
      }
    }
  }
}


.meeting-modal-drop {
  background: rgba(0, 0, 0, .65);
  z-index: 9997;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  transition: opacity .2s ease-in;

  &:not(.is-visible) {
    visibility: hidden;
    opacity: 0;
  }

  &.is-visible {
    opacity: 1;
    visibility: visible;
  }
}

.meeting-modal {
  width: 90%;
  height: 90vh;
  margin: 0 !important;
  background: #fff;
  left: 5%;
  top: 5%;
  z-index: 9998;
  position: fixed;

  &:not(.is-visible) {
    visibility: hidden;
    opacity: 0;
  }

  &.is-visible {
    opacity: 1;
    visibility: visible;
  }

  &.modal-dialog {
    height: calc(100% - 3.5rem);

    .modal-content {
      height: 100%;
    }
  }

  .stop-meeting-div {
    position: absolute;
    left: 50%;
    bottom: -20px;
    border-radius: 10px;
    z-index: 9999;

    button {
      border: 2px solid #fff;
    }
  }
}

.staff-available-switch {
  line-height: 45px;

  .react-toggle {
    float: left;
    margin-right: 10px;
    margin-top: 12px;
  }
}

.ml10px {
  margin-left: 10px;
}

.mr10px {
  margin-right: 10px;
}

.mt10px {
  margin-top: 10px;
}

.mb10px {
  margin-bottom: 10px;
}

.pl10px {
  padding-left: 10px;
}

.pr10px {
  padding-right: 10px;
}

.pt10px {
  padding-top: 10px;
}

.pb10px {
  padding-bottom: 10px;
}

.ml5px {
  margin-left: 5px;
}

.mr5px {
  margin-right: 5px;
}

.mt5px {
  margin-top: 5px;
}

.mb5px {
  margin-bottom: 5px;
}

.pl5px {
  padding-left: 5px;
}

.pr5px {
  padding-right: 5px;
}

.pt5px {
  padding-top: 5px;
}

.pb5px {
  padding-bottom: 5px;
}

.stand-logo-preview {
  max-width: 100%;
  height: auto;
}

.shadow-0 {
  box-shadow: 0 0 0 !important;
}

.fullTimeButton {
  position: relative;

  svg {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -10px;
  }
}

.company-logo {
  height: 120px;
  max-width: 100%;
  width: auto;
  margin: 0 auto;
}

.staff-panel-item {
  .company-logo {
    max-width: 100%;
    height: 100px;
    width: auto;
  }
}

.speaker-panel-item {
  .card-header {
    padding: 10px 20px;
  }
}

.category-tags {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: right;
}

.wall-item {
  background-color: #fdfdfd;

  p {
    font-size: 18px;
    line-height: 27px;
  }
}

.profile-company {
  font-size: 10px
}

.social-single-user {
  margin-bottom: 1rem;

  .card-body, .card-footer {
    padding: 0.5rem;
  }
}

.profile-name {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.chatbox-container {
  height: calc(100vh - 220px);
  width: 100%;
}

.sharebox-textarea {
  height: 90px !important;
  font-size: 16px !important;
  resize: none;
}

.clockFormat {
  font-size: 14px;
}

.wall-card {
  .card-header,
  .card-body {
    background-color: rgba(0, 0, 0, .02);
  }
}

.detail-amt {
  width: 80px;
}

.company-content-text {
  img {
    max-width: 100%;
    height: auto;
  }
}

.expo-cat-nav {
  .nav-item .nav-link, .nav-item .nav-link.active {
    color: #333 !important;
  }

  .nav-item .nav-link.active {
    font-weight: 800;
  }
}


.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

.team-member-company {
  width: 90%;
  margin-left: 5%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.staff-name-span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
}

.support-li {
  position: absolute !important;
  left: 0;
  bottom: 20px;
  width: 100%;

  .support-button {
    width: 80%;
    text-align: center;
    margin-left: 10%;
    box-shadow: 0 0 7px -3px #333;
    //width: 48px;
    //height: 48px;
  }
}

.current-time-counter {
  width: 165px;
}

.top-sponsors {
  width: 100%;
  height: 42px;

  .carousel {
    height: 35px;

    .carousel__slider {
      height: 35px;

      .carousel__slider-tray-wrap--horizontal {
        height: 35px;

        .carousel__slider-tray--horizontal {
          height: 35px;

          & > li {
            height: 35px;
          }
        }
      }
    }

    .carousel__inner-slide {
      height: 35px;
    }
  }

  img {
    margin-right: 10px;
  }
}

.unread-message-badge {
  right: -0.7rem !important;
  width: 20px;
  height: 20px;
  top: 5px !important;
}

.w500px {
  width: 500px;
}

.w450px {
  width: 450px !important;
}

.w350px {
  width: 350px !important;
}
.max-width-40{
  max-width: 40%;
}

.notification-navlink {
  padding: 1.3rem 0 !important;
}

.player .play-bar .vp-live-viewer-count,
.vp-live-viewer-count {
  display: none !important;
}

.m-r-l-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-schedule {
  .icon-border {
    border: 2px solid #fbba00
  }
}

.w100px {
  width: 100px;
}

.main-supporter-top {
  width: auto;
  height: 35px;
  float: left;
}

.program-name-title {
  white-space: normal;
}

.react-custom-player {
  position: relative;

  .play-pause-button {
    position: absolute;
    padding: 4px;
    text-align: center;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
    visibility: hidden;
    opacity: 0;
    transition: all 1s;

    background: $uzakrotaColor;
    color: #fff;

    border-radius: 4px;
    outline: none;
    border: 1px solid #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .35);
    padding: 0;
    line-height: 50px;

    &:hover {
      box-shadow: 0 3px 8px rgba(0, 0, 0, .35);
    }

  }

  &:hover, &.not-playing {
    .play-pause-button {
      transition: all 0.15s;
      visibility: visible;
      opacity: 1;
    }
  }
}

.custom-mini-player-container {
  &:not(.show) {
    display: none;
  }

  .custom-mini-player {
    position: fixed;
    right: 0;
    z-index: 999999;
    bottom: 0;

    .stage-selector-div {
      margin-top: -1px;
      padding: 8px 12.5px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      svg {
        path {
          stroke: #fff;
        }
      }

      span {
        float: left;
      }
    }

    .stage-selector {
      height: 26px;
      padding: 3px;
      float: left;
      margin-left: 10px;
      width: 120px;
      max-width: 90%;
      margin-top: -3px;
      margin-bottom: -3px;
    }

    .drag-navigation {
      position: fixed;
      right: 0;
      top: -30px;
      width: 100%;

      .button-item {
        background-color: #fff;
        border-radius: 100%;
        overflow: hidden;
      }
    }
  }
}

.homepage-widget {
  height: 140px;
  @media screen and (max-width: 1200px) {
    height: 90px;
  }
  width: 100%;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    margin-bottom: 20px;
  }

  .homepage-widget-bg {
    height: 140px;
    @media screen and (max-width: 1200px) {
      height: 90px;
    }
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &:before {
      position: absolute;
      content: " ";
      left: 0;
      top: 0;
      width: 100%;
      height: 140px;
      background-color: rgba(0, 0, 0, .35);


      -webkit-transition: all .5s;
      -moz-transition: all .5s;
      -o-transition: all .5s;
      transition: all .5s;

    }
  }

  &:hover {
    .homepage-widget-bg {
      -ms-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);

      &:before {
        background-color: rgba(0, 0, 0, .6);
      }
    }

    h4 {
      opacity: 1;
    }
  }

  h4 {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;

    position: absolute;
    bottom: 0px;
    color: #fff;
    right: 10px;
    text-transform: uppercase;
    font-size: 32px;
    @media screen and (max-width: 1200px) {
      font-size: 16px;
    }
    opacity: 0.7;
  }
}

.bell-animation {
  display: inline-block;
  color: $uzakrotaColor;
  -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.speakers-widget {
  .slick-prev:before, .slick-next:before {
    color: $uzakrotaColor;
  }

  .slick-prev {
    left: -15px
  }

  .slick-next {
    right: -15px
  }

  .name-row {
    width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
  }

  .company-row {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
