//.chatbox
.chat-sidebar {
  .chat-user-list {
    li {
      .user-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  transition: all .2s ease-in;

  &.hide-sidebar-right {
    right: -280px;
  }

  .chat-message-item{
    //border: 1px solid #CCC;
    border-radius: 10px;
    width: calc(100% - 12px);
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 6px;
    transition: background .2s;
    cursor: pointer;
    div.bubble{
      position: relative;
      border-radius: 10px;
      border: 1px solid #CCC;
      width: 100%;
      padding-bottom: 20px !important;
      .text{
        display: block;
        width: 100%;
      }
      .time{
        float: right;
        font-size: 11px;
        opacity: .5;
        font-style: italic;
      }
      &:before {
        // layout
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 10px;
        left: -10px; // offset should move with padding of parent
        border: .75rem solid transparent;
        // looks
        border-left: none;
        border-right-color: rgba(#ccc,1);
        filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
      }
    }
    &:hover{
      div.bubble{
      box-shadow: 0 0 3px #DDD;
        background-color: #f7f7f7;
      }
    }
  }



}

.show-chat-button {
  position: fixed;
  right: 10px;
  z-index: 999;
  top: 50%;
  margin-top: -25px;
  width: 40px;
  height: 40px;
}
